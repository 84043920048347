<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>SCRM</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/member_list' }">会员列表</el-breadcrumb-item>
            <el-breadcrumb-item>会员详情</el-breadcrumb-item>
         </el-breadcrumb>
         <el-button class="bg-gradient" type="primary" plain @click="goBack">返回</el-button>
      </el-row>
      <el-row class="content-box">
         <el-row class="basic-info">
            <el-row class="title"><b>基本信息</b></el-row>
            <el-row class="basic-top">
               <el-col :span="12">
                  <el-row class="m-bottom-10">
                     <p><span>姓 名：</span>{{nickName}}</p>
                     <p><span>微信号：</span>{{wecharId}}</p>
                  </el-row>
                  <el-row>
                     <p><span>籍 贯：</span>{{province}}</p>
                     <p><span>行 业：</span>{{industry}}</p>
                  </el-row>
               </el-col>
               <el-col :span="12">
                  <el-row class="m-bottom-10">
                     <p><span>手机号：</span>{{mobile}}</p>
                     <p><span>身份证号：</span>{{certificateNo}}</p>
                  </el-row>
                  <el-row>
                     <p><span>来 源：</span>{{userSource | filterSource}}</p>
                     <p><span>会员等级：</span>{{medalName}}</p>
                  </el-row>
               </el-col>
            </el-row>
            <el-row class="basic-btm">
               <el-row class="subtitle"><b>更多</b></el-row>
               <el-row class="basic-btm-cont">
                  <el-col :span="12">
                     <el-row class="m-bottom-10">
                        <p><span>性 别：</span>{{gender | filterGender}}</p>
                        <p><span>兴趣爱好：</span>{{hobby}}</p>
                     </el-row>
                     <el-row>
                        <p><span>邮 箱：</span>{{email}}</p>
                     </el-row>
                  </el-col>
                  <el-col :span="12">
                     <el-row class="m-bottom-10">
                        <p><span>生 日：</span>{{birthday}}</p>
                        <p><span>特殊要求：</span>{{specialService}}</p>
                     </el-row>
                     <el-row>
                        <p><span>备 注：</span>{{remark}}</p>
                     </el-row>
                  </el-col>
               </el-row>
            </el-row>
         </el-row>
         <el-row class="more-info">
            <el-col :span="12" class="check-box">
               <el-row class="common-info check-info">
                  <el-row class="title"><b>入住信息</b></el-row>
                  <el-row class="info-cont">
                     <el-row class="m-bottom-10">
                        <p><span>入住次数：</span>3</p>
                        <p><span>首次入住时间：</span>2021-1-12</p>
                     </el-row>
                     <el-row class="m-bottom-10">
                        <p><span>最后一次入住时间：</span>2021-1-12</p>
                     </el-row>
                     <!--                  <el-row class="look">
                                          <p>查看入住历史</p>
                                       </el-row>-->
                  </el-row>
               </el-row>
            </el-col>
            <el-col :span="12" class="consume-box">
               <el-row class="common-info consume-info">
                  <el-row class="title"><b>商城消费信息</b></el-row>
                  <el-row class="info-cont">
                     <el-row class="m-bottom-10">
                        <p><span>订单总量：</span>3</p>
                        <p><span>订单总金额：</span>1000</p>
                     </el-row>
                     <el-row class="m-bottom-10">
                        <p><span>首次订单时间：</span>2021-1-12</p>
                     </el-row>
                     <!--                  <el-row class="look">
                                          <p>查看消费历史</p>
                                       </el-row>-->
                  </el-row>
               </el-row>
            </el-col>
         </el-row>
      </el-row>
   </section>
</template>

<script>
   export default {
      data(){
         return{
            nickName: '',
            mobile: '',
            wecharId: '',
            certificateNo: '',
            province: '',
            source: '',
            industry: '',
            level: '',
            gender: '',
            birthday: '',
            hobby: '',
            medalName: '',
            specialService: '',
            email: '',
            remark: '',
            userSource: ''
         }
      },
      mounted() {
         this.getMemberDetail()
      },
      beforeDestroy() {
         sessionStorage.removeItem('memberInfo')
      },
      methods: {
         // 获取会员详情
         getMemberDetail(){
            let memberInfo = JSON.parse(sessionStorage.getItem('memberInfo'))
            this.nickName = memberInfo.nickName
            this.userSource = memberInfo.userSource
            this.medalName = memberInfo.medalName
            this.mobile = memberInfo.mobile
            this.wecharId = memberInfo.wecharId
            this.certificateNo = memberInfo.certificateNo
            this.province = memberInfo.province
            this.source = memberInfo.source
            this.industry = memberInfo.industry
            this.level = memberInfo.level
            this.gender = memberInfo.gender
            this.birthday = memberInfo.birthday
            this.hobby = memberInfo.hobby
            this.specialService = memberInfo.specialService
            this.email = memberInfo.email
            this.remark = memberInfo.remark
         },
         // 返回上页
         goBack(){
            this.$router.go(-1)
         }
      },
      filters:{
         filterSource(val){
            switch (val) {
               case 'APPLETS':
                  return val = '小程序'
               case 'SSM':
                  return val = '自助机'
               case 'APP':
                  return val = 'APP'
               case 'WEB':
                  return val = 'PC端'
               case 'PLATFORM':
                  return val = '平台'
               case 'SMS':
                  return val = '短信'
               case 'EMAIL':
                  return val = '邮件'
               case 'WECHAT_OFFICIAL':
                  return val = '微信公众号'
               case 'WECHAT_APPLET':
                  return val = '微信小程序'
               case 'QQ':
                  return val = 'QQ'
               case 'ALIPAY':
                  return val = '支付宝'
               case 'DINGTALK':
                  return val = '钉钉'
               case 'DOUYIN':
                  return val = '抖音'
               case 'XIAOHONGSHU':
                  return val = '小红书'
               case 'PMS':
                  return val = '酒店PMS'
            }
         },
         filterGender(val){
            switch (val) {
               case "MAN":
                  return val = '男'
               case "WOMAN":
                  return val = '女'
               case "SECRET":
                  return val = '保密'
            }
         }
      }
   }
</script>

<style scoped lang="scss">
   .cont { height: auto }
   .title{
      border-bottom: #e5e6e6 solid 1px; padding: 25px 0 10px 20px;
      font-size: 16px;
   }
   /* 基本信息 */
   .basic-info{
      box-shadow: 0 0 8px #DCE7F9; padding: 0 0 20px 0; color: #222222;
      span{ color: #9c9c9c }
      .basic-top{
         padding: 20px 100px; line-height: 30px;
      }
      .basic-btm{
         .subtitle{ padding: 0 0 0 20px }
         .basic-btm-cont { padding: 10px 100px; line-height: 36px; }
      }
   }
   /* 更多信息 */
   .more-info{
      margin-top: 20px; background-color: transparent;
      border-bottom: none; padding: 0; color: #000000;
      span{ color: #9c9c9c; }
      .check-box { padding-right: 15px;}
      .consume-box { padding-left: 15px;}
      .common-info{
         //background-color: #ffffff;
         box-shadow: 0 0 8px #DCE7F9;
         .info-cont{
            padding: 20px 0 20px 20px; line-height: 36px;
            .look{ color: #4fa2f7 }
         }
      }
   }
</style>

